import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import PlusIcon from 'wix-ui-icons-common/on-stage/Plus';
import { ROUTE_CREATE_POST } from '../../../../post-page/constants/routes';
import { connect } from '../../../../common/components/runtime-context';
import { getCreatePostUrl } from '../../../../common/services/get-post-url';
import { getTopology } from '../../../../common/store/topology/topology-selectors';
import PostLink from '../../../../common/components/link/post-link';
import withTranslate from '../../../../common/hoc/with-translate';
import { classes } from './create-post-button-mobile.st.css';

const CreatePostButtonMobile = ({ t, createPostUrl }) => (
  <Button
    as={PostLink}
    postLink={createPostUrl}
    postSlug={ROUTE_CREATE_POST}
    className={classes.root}
    priority={PRIORITY.secondary}
    prefixIcon={<PlusIcon />}
    fullWidth={true}
    upgrade={true}
    data-hook="create-post-button"
  >
    {t('create-post-button.label')}
  </Button>
);

CreatePostButtonMobile.propTypes = {
  t: PropTypes.func.isRequired,
  createPostUrl: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state) => ({
  createPostUrl: getCreatePostUrl(getTopology(state)),
});

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps),
)(CreatePostButtonMobile);
